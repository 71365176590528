import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import GetBadgeModal from '../component/GetBadgeModal';
import BadgesSection from '../component/BadgesSection';

// Assume this image is properly imported
import whoShouldAttendBgImage from "/pages-bg/11.png";

const WhoShouldAttendPage = () => {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const attendeeCategories = [
    {
      title: "FINANCIERS",
      items: [
        "Venture Capitalists",
        "Angel Investors",
        "Film Financing Institutions",
        "Financial Institutions",
        "Brands that Support Films",
        "Investors from Other Industries",
        "Private Equity Firms",
        "Government Agencies and Grants Bodies",
        "Film and Media Investment Funds"
      ]
    },
    {
      title: "INDUSTRY PROFESSIONALS",
      items: [
        "Film Producers",
        "Film Directors",
        "Screenwriters",
        "Film Distributors",
        "Film Sales Agents",
        "Film Production Companies",
        "Film Marketing and PR Professionals",
        "Film Exhibitors and Theaters",
        "Cinematographers",
        "Film Editors",
        "Sound Designers",
        "Visual Effects Artists"
      ]
    },
    {
      title: "STAKEHOLDERS & ENTHUSIASTS",
      items: [
        "Film and Media Associations",
        "Film Studios",
        "Media Companies",
        "Creative Agencies",
        "Technology Providers for Film Production",
        "Journalists and Film Critics",
        "Film Bloggers and Influencers",
        "Media Outlets (TV, Radio, Online Platforms)",
        "Film Schools and Universities",
        "Policy Makers in Media and Culture",
        "Film Commissions Representatives",
        "Film Enthusiasts",
        "Students and Academics in Film Studies"
      ]
    }
  ];

  const openModal = (badge) => {
    setSelectedBadge(badge);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBadge(null);
    setIsModalOpen(false);
  };

  return (
    <div className="relative bg-[#FAF0E6]">
      <PageHeader 
        title="WHO CAN ATTEND?" 
        description="The Africa Film Finance Forum (AFFF) aims to bring together a diverse group of participants from various industries."
        backgroundImage={whoShouldAttendBgImage}
      />
      <main className="mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6 mx-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12">
            <h2 className="font-header text-[3rem] font-bold mb-6 tracking-wider text-custom-base">Attendee Categories</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {attendeeCategories.map((category, index) => (
                <div key={index} className="bg-white rounded-md shadow-md overflow-hidden border border-gray-300">
                  <h3 className="font-roboto text-2xl font-bold p-4 bg-gray-100 text-custom-base">{category.title}</h3>
                  <ul className="list-disc pl-8 pr-4 py-4 space-y-2 font-lexend">
                    {category.items.map((item, i) => (
                      <li key={i} className="text-sm">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section className="text-center mt-8">
            <p className="font-bold text-lg mb-4 font-lexend">ACCREDITATION PORTAL IS NOW OPENED</p>
            <Link to="/register" className="bg-custom-red text-white px-6 py-3 rounded-md font-bold hover:bg-red-600 transition duration-300 inline-block font-lexend">
              GET YOUR BADGES
            </Link>
          </section>
        </div>
      </main>
      <Footer />

      <GetBadgeModal
        isOpen={isModalOpen}
        onClose={closeModal}
        plan={selectedBadge}
      />
    </div>
  );
};

export default WhoShouldAttendPage;