import React from "react";
import yanga from "../assets/yanga.png";
import truc from "../assets/TRUCSRwhite-Mq9en2fX 1.svg";
import genesis from "../assets/genesis.svg";
import silverbird from "../assets/silverbird.svg";
import bluepicture from "../assets/blue-pictures.png";
import viva from "../assets/viva.png";
import homeland from "../assets/homelands.svg";
import guardian from "../assets/guardian.svg";
import art from "../assets/art.svg";
import arivival from "../assets/festival.png";
import calabar from "../assets/carnival.png";
import coat from "../assets/fmf.png";
import fdan from "../assets/fdan.png";
import Naira from "../assets/naira.png";
import Man from "../assets/mango.png";
import Elon from "../assets/elontech.png";
import industry from "../assets/industry.png";
import newscentral from "../assets/newscentral.png";
import tvc from "../assets/tvc.png";
import thecable from "../assets/thecable.png";

// Import new partner images
import partner1 from "../assets/partners/1.png";
import partner2 from "../assets/partners/2.png";
import partner3 from "../assets/partners/3.png";
import partner4 from "../assets/partners/4.png";
import partner5 from "../assets/partners/5.png";
import partner6 from "../assets/partners/6.png";
import partner7 from "../assets/partners/7.png";
import partner8 from "../assets/partners/8.png";
import partner9 from "../assets/partners/9.png";

const PartnerLogo = ({ src, alt }) => {
  const isWider = ['Naira', 'guardian', 'industry', 'coat', 'thecable', 'partner2', 'partner3', 'fdan'].includes(alt);
  const needsGrayFill = ['bluepicture', 'fdan', 'partner2', 'partner3', 'partner9'].includes(alt);
  
  return (
    <div className="flex items-center justify-center w-full p-1">
      <div className={`h-20 flex items-center justify-center ${isWider ? 'w-[100%]' : 'w-1/2'} mx-auto`}>
        <img 
          src={src} 
          alt={alt} 
          className={`max-w-full max-h-full object-contain 
            ${alt === 'industry' ? 'filter invert' : ''} 
            ${needsGrayFill ? 'filter invert' : ''}`}
        />
      </div>
    </div>
  );
};

const PartnerSection = ({ title, partners }) => (
  <div className="mb-12">
    <h2 className="font-['Agharti_Condensed'] text-2xl md:text-3xl tracking-wider text-[#F0DFBC] text-center border-b-2 border-[#F0DFBC] pb-2 mb-6 mx-auto w-full max-w-[25rem]">
      {title}
    </h2>
    <div className="flex flex-wrap justify-center items-center">
      {partners.map((partner, index) => (
        <div key={index} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 px-2">
          <PartnerLogo {...partner} />
        </div>
      ))}
    </div>
  </div>
);

const Partners = () => {
  const agencyPartners = [
    { src: coat, alt: "coat" },
    { src: partner9, alt: "Partner 9" },
    { src: arivival, alt: "arivival" },
    { src: calabar, alt: "calabar" },
    { src: fdan, alt: "fdan" },
  ];

  const strategicPartners = [
    { src: partner7, alt: "Partner 7" },
    { src: partner8, alt: "Partner 8" },
    { src: truc, alt: "truc" },
    { src: Man, alt: "mango" },
    { src: Elon, alt: "elontech" },
    { src: yanga, alt: "yanga" },
  ];

  const industryPartners = [
    { src: silverbird, alt: "silverbird" },
    { src: viva, alt: "viva-cinema" },
    { src: bluepicture, alt: "blue-picture" },
    { src: homeland, alt: "home-land" },
    { src: partner1, alt: "Partner 1" },
    { src: partner2, alt: "Partner 2" },
    { src: partner3, alt: "Partner 3" },
    { src: partner4, alt: "Partner 4" },
    { src: partner5, alt: "Partner 5" },
    { src: partner6, alt: "Partner 6" },
  ];

  const mediaPartners = [
    { src: guardian, alt: "guardian" },
    { src: industry, alt: "industry" },
    { src: thecable, alt: "TheCable" },
    { src: tvc, alt: "TVC" },
    { src: newscentral, alt: "News Central" },
    { src: Naira, alt: "Naira" },

  ];

  return (
    <section className="bg-[#0B1C09] py-12 md:py-20">
      <div className="container mx-auto px-8 md:px-16 lg:px-24">
        <h1 className="font-['Agharti_Condensed'] text-4xl md:text-6xl lg:text-7xl text-gray-200 tracking-wider text-center mb-12">
          PARTNERS
        </h1>
        <PartnerSection title="AGENCY PARTNERS" partners={agencyPartners} />
        <PartnerSection title="STRATEGIC PARTNERS" partners={strategicPartners} />
        <PartnerSection title="INDUSTRY PARTNERS" partners={industryPartners} />
        <PartnerSection title="MEDIA PARTNERS" partners={mediaPartners} />
      </div>
    </section>
  );
};

export default Partners;