import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import FinancierAccreditationForm from '../component/FinancierAccreditationForm'; // Assuming you have this file for the form modal
import accreditationBgImage from "../assets/programmes-bg.jpg";

const FinancierAccreditationPage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  return (
    <div className="relative">
      <PageHeader 
        title="APPLY FOR FINANCIER ACCREDITATION" 
        description="Get your accredited Financier badge for AFFF SUMMIT 2025"
        backgroundImage={accreditationBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">BECOME AN ACCREDITED FINANCIER</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              To obtain an accredited Financier badge, kindly click the button below to start your application.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              An AFFF financier is a company or individual interested in funding various aspects of the film production
              and release process, including marketing, distribution, and more.
            </p>
            <p className="mb-4 text-sm sm:text-base">
            By completing and submitting your accreditation form, you are <strong>SOLELY applying for a badge - ACCREDITATION</strong>, IF YOU ARE ALREADY ACCREDITED PLEASE PROCEED TO PURCHASE YOUR BADGE</p>
            <p className="mb-4 text-sm sm:text-base">
            Our dedicated team will be available to help you before and during AFFF. Reach out via email at 
             <b>event@africafilmfinanceforum.com</b>.
            </p>
            <button 
                 onClick={openForm} 
                className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 text-center font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 mt-4"
              >
              Get Accredited Now
            </button>
          </section>

          
          
          

          {isFormOpen && (
            <FinancierAccreditationForm onClose={closeForm} />
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default FinancierAccreditationPage;
