import React from 'react';
import Pricing from './Pricing';

const PricingModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
      <div className="bg-white p-6 rounded-lg max-w-6xl w-full m-4">
        <button
          onClick={onClose}
          className="absolute top-5 right-5 bg-orange-600 border-r-8 p-10 shadow-lg text-white text[5rem] font-bold hover:text-gray-700"
        >
          Close
        </button>
        <Pricing />
      </div>
    </div>
  );
};

export default PricingModal;