import React, { useState } from 'react';
import { FaTwitter, FaLinkedin, FaFacebook, FaTimes } from 'react-icons/fa';
import api from '../services/api';

const RegistrationModal = ({ onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const [formData, setFormData] = useState({
    companyName: '',
    contactName: '',
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: ''
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^\+?[\d\s-]{10,}$/;
    return re.test(phone);
  };

  const validateForm = () => {
    if (!formData.companyName.trim()) {
      setError('Company name is required');
      return false;
    }
    if (!formData.contactName.trim()) {
      setError('Contact name is required');
      return false;
    }
    if (!formData.email.trim() || !validateEmail(formData.email)) {
      setError('Valid email address is required');
      return false;
    }
    if (!formData.phone.trim() || !validatePhone(formData.phone)) {
      setError('Valid phone number is required');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await api.post('/register', formData);
      
      if (response.data.success) {
        setSuccess('Registration successful! Check your email for confirmation.');
        setFormData({
          companyName: '',
          contactName: '',
          contactDesignation: '',
          companyAddress: '',
          city: '',
          state: '',
          country: '',
          zipCode: '',
          email: '',
          phone: ''
        });
        setTimeout(() => onClose?.(), 3000);
      } else {
        throw new Error(response.data.error || 'Registration failed');
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(
        err.response 
          ? err.response.data.error || 'Registration failed'
          : 'Unable to connect to the server. Please check your internet connection.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleShare = (platform) => {
    const shareText = "Join me at AFFF Connex 2024 - connecting film professionals with financiers. November 26-28, 2024.";
    const shareUrl = "https://africafilmfinanceforum.com";
    
    const shareLinks = {
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(shareText)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`
    };

    window.open(shareLinks[platform], '_blank', 'width=600,height=400,noopener,noreferrer');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="flex w-full max-w-7xl h-[80vh] mx-auto bg-white rounded-xl overflow-hidden shadow-lg relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 z-50 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FaTimes className="w-6 h-6" />
        </button>

        {/* Left Panel */}
        <div className="hidden lg:flex lg:w-1/3 bg-green-900 p-8 flex-col justify-between">
          <div className="relative">
            <div className="absolute top-0 left-0 w-48 h-32 bg-white rounded-lg shadow-md transform -rotate-6"></div>
            <div className="absolute top-4 left-4 w-48 h-32 bg-green-400 rounded-lg shadow-md transform rotate-3">
              <div className="p-5 leading-none mb-3">
                <div className="text-sm text-white">AFFF Connex Registration</div>
                <div className="text-sm text-white">Africa Film Finance Forum</div>
                <div className="text-sm text-white">Nov 26-28, 2024</div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-4xl font-bold text-white mb-3">AFFF Connex 2024</h2>
            <p className="text-lg text-green-100">
              Join us for an exclusive gateway to connect, collaborate, and co-create with the movers and shakers of the global film finance industry.
            </p>
            <div className="flex mt-6">
              <div className="w-3 h-3 bg-white rounded-full mr-2"></div>
              <div className="w-3 h-3 bg-green-400 rounded-full mr-2"></div>
              <div className="w-3 h-3 bg-green-400 rounded-full"></div>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-2/3 flex flex-col h-full">
          <div className="p-8 bg-white border-b">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-2">Register for AFFF Connex</h2>
              <p className="text-lg text-gray-600">Your gateway to the global film finance industry</p>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="p-8">
              <div className="max-w-4xl mx-auto">
                {/* Share Buttons */}
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-8">
                  <button 
                    onClick={() => handleShare('twitter')}
                    className="flex-1 flex items-center justify-center py-3 px-6 border border-blue-400 text-blue-400 rounded-md text-sm font-medium hover:bg-blue-50 transition-colors"
                  >
                    <FaTwitter className="mr-2 text-lg" /> Share on Twitter
                  </button>
                  <button 
                    onClick={() => handleShare('linkedin')}
                    className="flex-1 flex items-center justify-center py-3 px-6 border border-blue-700 text-blue-700 rounded-md text-sm font-medium hover:bg-blue-50 transition-colors"
                  >
                    <FaLinkedin className="mr-2 text-lg" /> Share on LinkedIn
                  </button>
                  <button 
                    onClick={() => handleShare('facebook')}
                    className="flex-1 flex items-center justify-center py-3 px-6 border border-blue-600 text-blue-600 rounded-md text-sm font-medium hover:bg-blue-50 transition-colors"
                  >
                    <FaFacebook className="mr-2 text-lg" /> Share on Facebook
                  </button>
                </div>

                {/* Divider */}
                <div className="relative mb-8">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-4 bg-white text-gray-500 text-lg">Register for AFFF Connex 2024</span>
                  </div>
                </div>

                {/* Registration Form */}
                <form id="registration-form" onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Company Name"
                      value={formData.companyName}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                    <input
                      type="text"
                      name="contactDesignation"
                      placeholder="Contact Designation"
                      value={formData.contactDesignation}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                  </div>

                  <input
                    type="text"
                    name="contactName"
                    placeholder="Contact Name"
                    value={formData.contactName}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    required
                  />

                  <input
                    type="text"
                    name="companyAddress"
                    placeholder="Company Address"
                    value={formData.companyAddress}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                    required
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                    <input
                      type="text"
                      name="state"
                      placeholder="State"
                      value={formData.state}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={formData.country}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="ZIP/Post Code"
                      value={formData.zipCode}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                  </div>

                  {error && (
                    <div className="p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
                      {error}
                    </div>
                  )}
                  
                  {success && (
                    <div className="p-4 bg-green-50 border border-green-200 text-green-700 rounded-md">
                      {success}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>

          <div className="p-8 bg-white border-t">
            <div className="max-w-4xl mx-auto">
              <button
                type="submit"
                form="registration-form"
                disabled={isSubmitting}
                className={`w-full p-3 rounded-md text-white font-medium ${
                  isSubmitting 
                    ? 'bg-green-400 cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-700'
                } transition-colors duration-300 flex items-center justify-center`}
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing Registration...
                  </>
                ) : 'Register for AFFF Connex'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;