import React, { useState } from 'react';
import PaymentStep from './PaymentStep';
import { initiatePayment } from '../services/api';

const GetBadgeModal = ({ isOpen, onClose, plan }) => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true);
    setError('');
    try {
      const response = await initiatePayment(
        formData.email, 
        plan.price, 
        'USD', 
        'badge', 
        { 
          ...formData, 
          badgeType: plan.name, 
          price: plan.price,
          badgeCount: plan.badgeCount,
          isAnnual: plan.isAnnual
        }
      );
      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        throw new Error('No redirect URL received');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setError('There was an error initiating the payment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen || !plan) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-[1.5rem] font-roboto font-bold mb-4">Your {plan.name} order...</h2>
        {step === 1 ? (
          <div>
            <p className="mb-4">You've selected the <b> {plan.name} </b> plan.</p>
            <p className="mb-4">
              Price: <b> ${plan.price} </b>
              {plan.isAnnual ? ` for ${plan.badgeCount} badges` : ''}
            </p>
            <form className="space-y-4">
              {Object.keys(formData).map((key) => (
                <div key={key}>
                  <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  </label>
                  <input
                    type={key === 'email' ? 'email' : 'text'}
                    name={key}
                    id={key}
                    value={formData[key]}
                    onChange={handleChange}
                    className="form-input w-full"
                    required
                  />
                </div>
              ))}
              <button
                onClick={() => setStep(2)}
                className="bg-custom-red text-white px-4 py-2 rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
              >
                Proceed to Payment
              </button>
            </form>
          </div>
        ) : (
          <PaymentStep
            amount={plan.price}
            onBack={() => setStep(1)}
            onSubmit={handlePaymentSubmit}
            isSubmitting={isSubmitting}
            error={error}
          />
        )}
        <button
          onClick={onClose}
          className="mt-4 text-gray-500 hover:text-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default GetBadgeModal;