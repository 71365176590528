import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import NavigationBar from "../component/NavigationBar";
import filmPremierBgImage from "../assets/film-premier-bg.jpg"; // Make sure this image exists

const FilmPremierPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="FILM PREMIER" 
        description="Experience the glamour and excitement of exclusive film premieres at AFFF."
        backgroundImage={filmPremierBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">FILM PREMIER</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              AFFF film premier, a third-day extravaganza, dedicated to celebrating the dynamic African film industry
              with a star-studded cast of VIP guests in the spotlight. This is not your average screening – it's a pulse-
              pounding showcase of Africa's cinematic brilliance, featuring exclusive unveilings of the most captivating
              and acclaimed films from the continent.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Prepare to be swept away by the sheer diversity and storytelling mastery on display, as each film bursts
              onto the screen with a whirlwind of emotions, cultural richness, and artistic genius. With the red carpet
              rolled out and the cameras flashing, brace yourself for a night of glitz, glamour, and cinematic magic as
              we salute the incredible creativity and impact of African filmmakers on the world stage.
            </p>
            <h3 className="font-header text-xl sm:text-2xl md:text-3xl font-bold mb-4 tracking-wider text-custom-base">SPONSORSHIP OPPORTUNITIES</h3>
            <p className="mb-4 text-sm sm:text-base">
              Sponsoring this prestigious event offers numerous benefits, including:
            </p>
            <ul className="list-disc pl-6 mb-6 space-y-2 text-sm sm:text-base">
              <li>Customized Partnership Packages</li>
              <li>Naming Rights</li>
              <li>Showcase of Products/Services</li>
              <li>Brand Visibility</li>
              <li>Event Recognition</li>
              <li>Engagement Opportunities</li>
            </ul>
            <Link 
              to="/contact"
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 text-center font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 inline-block"
            >
              CONTACT US FOR MORE INFORMATION
            </Link>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default FilmPremierPage;