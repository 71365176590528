import React, { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../component/PageHeader";
import contactBgImage from "/contact-bg.jpg";
import Footer from "./Footer";

// Create axios instance with default config and debug logging
const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://updates.mtnasap.cloud/afff/api',
  timeout: 10000, // Reduced timeout
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: status => status < 500 // Don't reject if status is less than 500
});

// Debug interceptors
api.interceptors.request.use(request => {
  console.log('🚀 Request:', {
    url: request.url,
    method: request.method,
    data: request.data,
    headers: request.headers
  });
  return request;
});

api.interceptors.response.use(
  response => {
    console.log('✅ Response:', {
      status: response.status,
      data: response.data
    });
    return response;
  },
  error => {
    console.log('❌ Error:', {
      message: error.message,
      code: error.code,
      response: error.response?.data,
      status: error.response?.status
    });
    return Promise.reject(error);
  }
);

function Contact() {
  const [formData, setFormData] = useState({
    subject: "General enquiries",
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 2;

  // Clear messages after 5 seconds
  useEffect(() => {
    let timeoutId;
    if (error || success) {
      timeoutId = setTimeout(() => {
        setError("");
        setSuccess("");
      }, 5000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [error, success]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError("");
    setSuccess("");
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!formData.name.trim()) {
      setError("Please enter your name");
      return false;
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!formData.message.trim()) {
      setError("Please enter your message");
      return false;
    }
    if (formData.message.length < 10) {
      setError("Message is too short. Please provide more details.");
      return false;
    }
    return true;
  };

  const submitToEndpoint = async (endpoint) => {
    try {
      console.log(`Attempting submission to ${endpoint}...`);
      const response = await api.post(endpoint, formData);
      
      if (response.data.success) {
        setSuccess("Thank you for reaching out! We've received your message and will get back to you shortly.");
        setFormData({
          subject: "General enquiries",
          name: "",
          email: "",
          message: "",
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error(`Error submitting to ${endpoint}:`, error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      // Try primary endpoint
      try {
        const success = await submitToEndpoint('/contact-page');
        if (success) return;
      } catch (error) {
        if (error.code !== 'ECONNABORTED' && error.response?.status !== 404) {
          throw error;
        }
        console.log('Primary endpoint failed, trying fallback...');
      }

      // Try fallback endpoint
      try {
        const success = await submitToEndpoint('/contact');
        if (success) return;
      } catch (error) {
        if (error.code === 'ECONNABORTED' && retryCount < maxRetries) {
          setRetryCount(prev => prev + 1);
          setError(`Connection timeout. Retrying... (${retryCount + 1}/${maxRetries + 1})`);
          setTimeout(() => handleSubmit(e), 1000);
          return;
        }
        throw error;
      }

    } catch (error) {
      console.error("Final submission error:", error);
      
      if (error.code === "ECONNABORTED") {
        setError("Unable to connect to the server. Please try again later or email us directly at businessdev@okhma.com");
      } else if (error.response) {
        switch (error.response.status) {
          case 400:
            setError(error.response.data.message || "Please check your input and try again.");
            break;
          case 429:
            setError("Too many requests. Please try again in a few minutes.");
            break;
          default:
            setError("Server error. Please try again later or email us directly at businessdev@okhma.com");
        }
      } else {
        setError("An unexpected error occurred. Please try again or contact us directly at businessdev@okhma.com");
      }
    } finally {
      setIsSubmitting(false);
      setRetryCount(0);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <PageHeader 
        title="CONNECT WITH US" 
        description="We're excited to hear from you! Whether you have questions about the Africa Film Finance Forum or want to explore collaboration opportunities, we're here to help."
        backgroundImage={contactBgImage}
      />  
      <main className="flex-grow bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12">
            {/* Contact Information */}
            <div className="lg:w-1/3 border-2 border-custom-red p-8 rounded-lg shadow-md bg-gray-50">
              <h2 className="text-2xl font-bold mb-6 text-custom-red">Get in Touch</h2>
              <p className="mb-6 text-gray-700">
                Thank you for your interest in the Africa Film Finance Forum 2024. We're looking forward to connecting with you!
              </p>
              <p className="mb-6 text-gray-700">
                For all inquiries, please reach out to our event organizers:
              </p>
              <div className="mb-6">
                <p className="font-bold text-lg text-gray-800">Okhma</p>
                <p className="text-gray-700">3b Bode Ajakaiye Road,</p>
                <p className="text-gray-700">Atlantic View Estate, Lekki,</p>
                <p className="text-gray-700">Lagos, NIGERIA</p>
              </div>
              <div className="text-gray-700">
                <p className="mb-2">
                  <span className="font-semibold">Phone:</span>{" "}
                  <a href="tel:+2348035657616" className="hover:text-custom-red transition-colors">+234 803 565 7616</a>,{" "}
                  <a href="tel:+2348146558068" className="hover:text-custom-red transition-colors">+234 814 655 8068</a>
                </p>
                <p>
                  <span className="font-semibold">Email:</span>{" "}
                  <a href="mailto:businessdev@okhma.com" className="hover:text-custom-red transition-colors">
                    businessdev@okhma.com
                  </a>
                </p>
              </div>
            </div>

            {/* Contact Form */}
            <div className="lg:w-2/3 bg-white p-8 rounded-lg shadow-lg border border-gray-200">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Send Us a Message</h2>
              <p className="mb-6 text-gray-600">
                Have a specific question or proposal? Use the form below to reach out directly. We aim to respond within 24-48 hours.
              </p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                    Nature of Inquiry
                  </label>
                  <select
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                    disabled={isSubmitting}
                  >
                    <option value="General enquiries">General Enquiries</option>
                    <option value="sponsorship">Sponsorship Opportunities</option>
                    <option value="accreditation">Accreditation & Exhibition</option>
                    <option value="cinetour">CineTour Information</option>
                    <option value="deal-table">Deal Table Participation</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                      Your Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    Your Message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="5"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-red focus:border-custom-red"
                    required
                    disabled={isSubmitting}
                    placeholder="Please provide details about your inquiry or how we can assist you."
                  ></textarea>
                </div>

                {error && (
                  <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{error}</p>
                      </div>
                    </div>
                  </div>
                )}

                {success && (
                  <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-md">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-green-700">{success}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`
                      px-6 py-3 bg-custom-red text-white font-medium rounded-md
                      transition duration-150 ease-in-out
                      ${isSubmitting 
                        ? "opacity-50 cursor-not-allowed" 
                        : "hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red"
                      }
                    `}
                  >
                   {isSubmitting ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sending...
                      </span>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;