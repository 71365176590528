import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import { Icon } from '@iconify/react';
import brandSponsorBgImage from "/sponsorship.jpg";

const BrandsSponsorshipPackagesPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const sponsorshipPackages = [
    {
      title: "AFFF Kiddies Corner",
      price: "$1,800",
      description: "Secure the naming rights to the Kiddies Corner, a vibrant space dedicated to entertaining children with rich African animation and content.",
      benefits: [
        "Prominent branding at the entrance",
        "Brand logo featured in AFFF magazines and all promotional materials",
        "Extensive visibility and association with quality family entertainment"
      ]
    },
    {
      title: "AFFF Film Screening",
      price: "$3,000",
      description: "Obtain the exclusive privilege of naming the AFFF Screenings.",
      benefits: [
        "Significant branding exposure",
        "Display of promotional materials",
        "Showcasing videos on multiple screens",
        "Advertisement in the AFFF magazine and promotional materials"
      ]
    },
    {
      title: "Pitch Sessions",
      price: "$3,000",
      description: "Gain Institutional Recognition in Pitch Sessions.",
      benefits: [
        "Speaking opportunities",
        "Exhibition space",
        "Access to the Investor's Room and Lounge",
        "Exclusive investment rights for the winning session",
        "Restriction on the winning brand from pitching film projects to non-sponsoring entities for the pitch session at the forum"
      ]
    },
    {
      title: "Investors Lounge",
      price: "$3,000",
      description: "Secure naming right to the Investor's Room at AFFF, a cozy lounge where you can enjoy breakfast and lunch.",
      benefits: [
        "Speaking opportunities",
        "Exhibition space",
        "Promotion of the company, particularly in the exhibition corner of the Investor's Lounge"
      ]
    },
    {
      title: "AFFF CineTour Expo",
      price: "$6,000",
      description: "Secure naming rights for CineTour Expo, the exhibition space for international film commissions and production service companies and tourist attractions.",
      benefits: [
        "High-visibility branding at the entrance",
        "Placement of your brand logo in AFFF magazine and promotional materials"
      ]
    },
    {
      title: "AFFF Master class",
      price: "$6,000",
      slots: "2 brands",
      description: "Secure the prestigious title sponsorship rights for the AFFF Master Class, an elite platform for advanced industry learning featuring expert tutors from the film and finance industries.",
      benefits: [
        "Logo prominently displayed throughout the learning room",
        "Exclusive speaking opportunities",
        "Positioning your brand as a thought leader",
        "Prominent recognition throughout the Master Class"
      ]
    },
    {
      title: "Africa Film Finance Forum",
      price: "$18,000",
      isBestValue: true,
      description: "Secure title sponsorship right for The AFFF Sessions, a three days prestigious forum featuring over 50 influential experts and thought leaders.",
      benefits: [
        "Speaking opportunity",
        "Comprehensive branding across the venue",
        "Advertisement in AFFF materials",
        "Prominent logo visibility",
        "Exclusive access to the Deal Table",
        "Entry to the Investor's Lounge",
        "Exhibition space",
        "Exclusive ownership of an Investors Room for networking and meetings"
      ]
    },
    {
      title: "Film Premier",
      price: "$19,000",
      description: "Sponsor the Film Premiere exclusively for VIP guests and industry professionals from the film and finance sectors, held on the 3rd night of AFFF.",
      benefits: [
        "Featuring Sponsor and AFFF logos on branded signage",
        "Showcasing a promotional video on monitors within the party",
        "Displaying the Sponsor logo on the official invitation to AFFF guests",
        "Advertisement in the AFFF magazine"
      ]
    }
  ];

  const bundledPackages = [
    {
      title: "Elite Bronze Sponsor",
      price: "$19,000",
      benefits: [
        "Logo placement on event materials and venues",
        "Access to networking opportunities with industry professionals",
        "VIP Access",
        "Brand Exposure",
        "Presence in event marketing materials and press releases",
        "Sponsorship recognition for delegates from other African countries and internationally",
        "Sponsor Delegates across Africa"
      ]
    },
    {
      title: "Elite Gold Sponsor",
      price: "$30,200",
      isBestValue: true,
      benefits: [
        "Prominent placement of sponsor's logo and branding across event materials and venues",
        "Networking Opportunities",
        "Access to VIP networking lounges for connections and collaborations",
        "Industry Recognition",
        "Exclusive Access",
        "Prominent Branding",
        "Branding in marketing materials, including event website, social media, and press releases",
        "Advertising Opportunity to place ads in event programs",
        "Speaking Opportunities",
        "Sponsorship recognition for delegates from other African countries and internationally"
      ]
    },
    {
      title: "Headline Sponsor",
      price: "$36,200",
      slots: "2 Slots – one finance and one brand that sponsor movies",
      benefits: [
        "Exclusive Headline positioning",
        "Prominent placement of sponsor's logo and branding as the headline sponsor of the event",
        "VIP Networking Opportunities",
        "Access to VIP networking lounges for high-level connections and collaborations",
        "VIP exclusive access",
        "Industry Positioning. Batch Branding/Registration/ Merchandise Branding",
        "Logo placement on registration materials and branded merchandise",
        "Logo placement and recognition during raffle sessions",
        "Logo placement and recognition during breakfast and lunch sessions",
        "Advertising Opportunities",
        "Opportunity to participate as a keynote speaker or panelist, showcasing expertise and thought leadership",
        "Sponsorship recognition during the exhibit and showcase of upcoming projects screenings",
        "Sponsorship recognition during cultural showcases featuring traditional music, dance, and art",
        "Recognition during live script readings, showcasing potential projects to investors",
        "Recognition during film screenings, providing visibility and investment opportunities",
        "Sponsorship recognition during media engagements and press conferences",
        "Recognition during sponsored masterclasses for students",
        "Opportunity to sponsor pitching sessions (pitch money exclusive of sponsorship)",
        "Exclusive sponsorship of the film finance hackathon, fostering innovation and problem-solving",
        "Logo placement and recognition in travel and accommodation arrangements",
        "Thought Leadership Cultural Showcases",
        "Recognition during sponsored masterclasses",
        "Investors Cocktail"
      ]
    }
  ];

  const PackageCard = ({ thePackage, isBundle }) => (
    <motion.div 
      className={`bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl ${thePackage.isBestValue ? 'ring-2 ring-custom-red' : ''}`}
      whileHover={{ scale: 1.03 }}
      layout
    >
      {thePackage.isBestValue && (
        <div className="bg-custom-red text-white text-center py-1 font-bold">
          BEST VALUE
        </div>
      )}
      <div className="p-6">
        <h3 className="font-roboto text-2xl font-bold mb-2 text-custom-base">{thePackage.title}</h3>
        <p className="text-3xl font-bold text-custom-red mb-4">{thePackage.price}</p>
        {thePackage.slots && <p className="mb-4 text-sm font-bold text-gray-600">{thePackage.slots}</p>}
        <p className="mb-4 text-sm text-gray-600">{thePackage.description}</p>
        <h4 className="font-bold mb-2 text-custom-base">Benefits:</h4>
        <ul className="space-y-2">
          {thePackage.benefits.map((benefit, i) => (
            <li key={i} className="flex items-start">
              <Icon icon="mdi:check-circle" className="text-custom-red mr-2 mt-1 flex-shrink-0" />
              <span className="text-sm text-gray-700">{benefit}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="px-6 py-4 bg-gray-50">
        <button 
          onClick={() => setShowContactForm(true)}
          className="w-full bg-custom-red text-white px-4 py-2 rounded-full font-bold hover:bg-red-600 transition duration-300"
        >
          Contact Us
        </button>
      </div>
    </motion.div>
  );

  return (
    <div className="relative bg-gray-100">
      <PageHeader 
        title="BRANDS SPONSORSHIP PACKAGES" 
        description="Support movies and gain exceptional visibility at AFFF"
        backgroundImage={brandSponsorBgImage}
      />
      <main className="py-12 px-4 sm:px-6 lg:px-8 font-lexend">
        <div className="max-w-7xl mx-auto">
          <section className="mb-16">
            <h2 className="font-header text-[6rem] sm:text-[4rem] tracking-wider font-bold mb-8 text-center text-custom-base">Sponsorship Packages for Brands</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
              {sponsorshipPackages.map((thePackage, index) => (
                <PackageCard key={index} thePackage={thePackage} isBundle={false} />
              ))}
            </div>
          </section>

          <section>
            <h2 className="font-header text-[6rem] sm:text-[4rem] tracking-wider font-bold mb-8 text-center text-custom-base">Bundled Sponsorship Packages</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
              {bundledPackages.map((thePackage, index) => (
                <PackageCard key={index} thePackage={thePackage} isBundle={true} />
              ))}
            </div>
          </section>

          <section className="text-center mt-16">
            <button 
              onClick={() => setShowContactForm(true)} 
              className="bg-custom-red text-white px-8 py-3 font-bold text-lg rounded-full hover:bg-red-600 transition duration-300 shadow-lg hover:shadow-xl"
            >
              GET CUSTOM PRICING
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BrandsSponsorshipPackagesPage;