import React from "react";
import Attend from "../component/Attend";

function AttendPage() {
  return (
    <>
      <Attend />
    </>
  );
}

export default AttendPage;