import React from "react";
import { Link } from "react-router-dom";
import dealTableBackground from "../assets/deal-table.jpg";

const DealTable = () => {
  return (
    <section className="relative min-h-screen w-full overflow-hidden font-sans">
      {/* Background image */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${dealTableBackground})`,
        }}
      ></div>
      
      {/* Black overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      
      {/* Centered content */}
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 md:p-8 lg:p-10 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[60%] flex flex-col justify-center items-center text-white">
          <h2 className="font-header text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-center tracking-wider">WHERE VISION MEETS INVESTMENT</h2>
          <p className="text-center mb-6 text-sm md:text-base lg:text-lg xl:text-xl">
            The ultimate nexus for visionary filmmakers and discerning investors. 
            This exclusive space is designed to foster meaningful connections, 
            facilitate high-impact negotiations, and secure groundbreaking 
            partnerships. Join us to turn creative dreams into financial realities 
            and unlock the immense potential of Africa's film and creative 
            industries.
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4">
            <Link 
              to="/exhibit/deal-table" 
              className="bg-custom-red shadow-md text-white px-4 md:px-6 py-2 md:py-3 rounded-tl-lg rounded-br-lg font-bold text-[1.2rem] flex items-center justify-center gap-2 transition duration-300 ease-in-out hover:bg-black hover:text-white"
            >
              Apply to Get on the Deal Table
            </Link>
            <Link 
              to="/register" 
              className="bg-transparent border-2 border-white text-white px-4 md:px-6 py-2 md:py-3 rounded-tl-lg rounded-br-lg font-bold text-[1.2rem] flex items-center justify-center gap-2 transition duration-300 ease-in-out hover:bg-white hover:text-custom-red"
            >
              Get Your Premium Badge
            </Link>
          </div>
        </div>
      </div>

      {/* The Deal Table title */}
      <div className="absolute inset-x-0 bottom-0 flex items-end justify-center">
        <h1 className="font-antonio text-[12vw] sm:text-[10vw] md:text-[8vw] font-extrabold text-white text-center leading-none mb-[-0.1em]" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
          THE DEAL TABLE
        </h1>
      </div>
    </section>
  );
};

export default DealTable;