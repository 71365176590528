import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import SpeakersCard from "./SpeakersCard";

const speakersData = [
  { id: 1, name: "Joy Odetie", title: "CEO, Blue Pictures Entertainment", company: "President, Film Distribution Association of Nigeria (FDAN)" },
  { id: 2, name: "Jete Amata", title: "Filmaker", company: "Black November" },
  { id: 3, name: "Hamid Ibrahim", title: "Production Designer", company: "Iwaju" },
  { id: 4, name: "Opeoluwa Filani", title: "Industry Veteran", company: "Former General Manager, Showmax - Nigeria" },
  { id: 5, name: "Moses Babatope", title: "Group CEO", company: "The Nile Media Entertainment Group" },
  { id: 6, name: "Ekene Som Mekwunye", title: "Filmmaker, One Lagos Night", company: "Creative Head, Riverside Production - Nigeria" },
  { id: 7, name: "Funmi Onuma", title: "Country Manager", company: "Silverbird Cinemas - Ghana" },
  { id: 8, name: "Kelvin Osoo", title: "Film Director & CEO", company: "Different Dimension Productions - Kenya" },
  { id: 9, name: "Mary Ephraim", title: "President, Homelands Films", company: "National Secretary, Film Distribution Association of Nigeria (FDAN)" },
  { id: 10, name: "Mr. Obinna Okerekeocha", title: "Film Producer", company: "Director of Content, Moniepoint Inc" },
  { id: 11, name: "Mr. Promise George", title: "Group Head, Creatives and Digital Industries", company: "Bank of Industry" },
  { id: 12, name: "Edward Stevenson", title: "Industry Veteran & Retail Consultant", company: "ES Retail Investment LTD" },
  { id: 13, name: "Sir. Gabe Onah", title: "Chairman", company: "The Carnival Calabar Commission" },
  { id: 14, name: "Charles Eduebu", title: "Director-General", company: "National Broadcasting Commission" },
  { id: 15, name: "Bar. Isioma Idigbe", title: "Partner", company: "Punuka Attorneys & Solicitors" },
  { id: 16, name: "Ken Egbas", title: "Senior Adviser and Consultant", company: "Federal Minister of Sport Development, Nigeria" },
  { id: 17, name: "Amb. Ikechi Uko", title: "Travel Consultant", company: "Organizer of Akwaaba African Travel Market" },
  { id: 18, name: "Clarina De Freitas", title: "National Recruitment Specialist", company: "BizCommunity" },
  { id: 19, name: "Mr Derick", title: "", company: "" },
  { id: 20, name: "Mrs Ebele", title: "", company: "" },
  { id: 21, name: "Dr. Shaibu Husseini", title: "Director General", company: "The National Film and Video Censors Board (NFVCB)" },
  { id: 22, name: "Steve Gukas", title: "Film Director", company: "Living in Bondage" },
  { id: 23, name: "Farouk Khailann", title: "Executive Board Member", company: "Global Wealth Forum" },
  { id: 24, name: "Brenda Fashugba", title: "Regional Lead Creative Economy SSA/Head of Arts Nigeria", company: "British Council" },
  { id: 25, name: "Hamisha Daryani Ahuja", title: "Film Producer", company: "Namaste Wahala" },
  { id: 26, name: "Nora Awolowo", title: "Creative Director", company: "Rixel Studios" },
  { id: 27, name: "Bar. Yahaya Maikori", title: "Media Attorney, Member Board of Directors", company: "VFD MICROFINANCE BANK" },
  { id: 28, name: "Omotayo Inakoju", title: "Head Legal", company: "EbonyLife Group" },
  { id: 29, name: "Dr. Mories Atoki", title: "CEO", company: "ABCHealth" },
  { id: 30, name: "Victoria Abiola Ajayi", title: "CEO", company: "TVC Communications" },
];

const OtherSpeakers = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const NextArrow = ({ onClick }) => (
    <button onClick={onClick} className="absolute right-0 top-1/2 -translate-y-1/2 z-10">
      <ChevronRight size={24} />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button onClick={onClick} className="absolute left-0 top-1/2 -translate-y-1/2 z-10">
      <ChevronLeft size={24} />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setActiveSlideIndex(current),
    appendDots: (dots) => (
      <div className="flex items-center justify-end">
        <ul className="m-0"> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`w-4 h-4 border-2 border-[#F16764] rounded-full ${
          i === activeSlideIndex ? "bg-[#F16764]" : "bg-transparent"
        }`}
      />
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="bg-[#FAF0E6] py-12 relative overflow-hidden px-8">
      <div className="container mx-auto">
        <h2 className="font-['Agharti_Condensed'] text-[4rem] font-bold mb-4 text-[#2C3A4B] tracking-wider">OTHER SPEAKERS</h2>
        <p className="text-lg mb-6 max-w-2xl">
          Get ready to hear from a lineup of powerhouse speakers. Learn more about our
          inspiring speakers and the insights they'll be sharing at AFFF.
        </p>
        <Link 
          to="/programmes/forum-speakers" 
          className="border-2 border-[#2C3A4B] text-[#2C3A4B] px-6 py-2 mb-3 hover:bg-[#2C3A4B] hover:text-white transition-colors"
        >
          VIEW ALL SPEAKERS
        </Link>

        <div className="w-full my-24 mx-auto">
          <Slider {...settings}>
            {speakersData.map((speaker, index) => (
              <SpeakersCard
                key={speaker.id}
                id={speaker.id}
                image={`/speakers/${speaker.id.toString().padStart(2, '0')}.jpg`}
                name={speaker.name}
                title={speaker.title}
                company={speaker.company}
                isActive={activeSlideIndex === index}
                backgroundColor={index % 2 === 0 ? 'rgba(241, 103, 100, 1)' : '#5EBAB0'}
              />
            ))}
          </Slider>
        </div>
      </div>

      {/* Custom styles for Slider */}
      <style>{`
        .slick-slide {
          transition: all 0.3s ease;
          padding: 0 10px;
        }
        .slick-current {
          z-index: 10;
        }
        .slick-list {
          overflow: visible;
          margin: 0 -10px;
        }
        .slick-track {
          display: flex;
          align-items: center;
        }
        .slick-dots {
          bottom: -40px;
          text-align: right;
          width: 100%;
          padding-right: 20px;
        }
        .slick-dots li {
          margin: 0 4px;
        }
      `}</style>
    </section>
  );
};

export default OtherSpeakers;