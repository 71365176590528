import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import marketReviewBgImage from "../assets/market-review-bg.jpg"; // Make sure this image exists

const MarketReviewSessionPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="MARKET REVIEW SESSION" 
        description="Gain insights into the African film industry's past, present, and future potential."
        backgroundImage={marketReviewBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">MARKET REVIEW SESSION</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              The market review session will provide a comprehensive overview of the African film industry, tracing its
              origins, examining its current state, and envisioning its future potential. Exclusive access for accredited
              financiers and sales and production companies, the session will highlight the industry's historical
              milestones, current market dynamics, challenges, and opportunities.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Emphasis will be placed on strategic collaboration and innovative financing models to maximize returns on investment.
              Attendees will gain insights into successful case studies, best practices, and emerging trends, fostering a deeper
              understanding and stronger partnerships for sustainable growth in the African film market.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              The session offers a forward-looking perspective, envisioning the future potential of the African film
              industry. Key trends such as digital disruption, audience engagement strategies, and cross-border
              collaborations are explored in depth, offering attendees a glimpse into the exciting possibilities and
              innovations that lie ahead.
            </p>
            <Link 
              to="/attend"
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 text-center font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 inline-block"
            >
              REGISTER TO ATTEND
            </Link>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MarketReviewSessionPage;