import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import SessionsCard from '../component/SessionsCard';
import exhibitBgImage from "/accredited-companies.jpg";

const AccreditedFilmProductionCompaniesPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="SALES AND PRODUCTION" 
        description="Engage with over 3,000 global participants at AFFF, offering unparalleled networking opportunities and insights."
        backgroundImage={exhibitBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-[3rem] sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">SALES AND PRODUCTION</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
            An AFFF Film Industry Professional is a company or individual actively involved in the production, financing, distribution, or creative development of films within the African film industry or its international counterpart. These professionals include:
            </p>
            <div className='flex gap-8'>
              <div className='flex flex-row w-1/2'>
              <ul className="space-y-3">
                <li className="p-3 border border-gray-300 rounded-lg bg-gray-100 shadow-sm">
                  <h3 className='font-roboto font-bold tracking-wider text-xl'>Filmmakers:</h3> Directors, producers, screenwriters, and cinematographers responsible for creating films.
                </li>
                <li className="p-3 border border-gray-300 rounded-lg bg-gray-100 shadow-sm">
                  <h3 className='font-roboto font-bold tracking-wider text-xl'>Distributors:</h3> Companies or individuals managing the distribution of films to cinemas, online platforms, and international markets.
                </li>
                <li className="p-3 border border-gray-300 rounded-lg bg-gray-100 shadow-sm">
                  <h3 className='font-roboto font-bold tracking-wider text-xl'>Sales Agents:</h3> Professionals focused on acquiring films and securing their distribution rights to maximize audience reach.
                </li>
                <li className="p-3 border border-gray-300 rounded-lg bg-gray-100 shadow-sm">
                  <h3 className='font-roboto font-bold tracking-wider text-xl'>Production Companies:</h3> Companies that develop, finance, and produce films, often managing the entire filmmaking process from concept to completion.
                </li>
                <li className="p-3 border border-gray-300 rounded-lg bg-gray-100 shadow-sm">
                  <h3 className='font-roboto font-bold tracking-wider text-xl'>Creative Artists:</h3> Actors, editors, set designers, and other creatives who contribute to the artistic elements of filmmaking.
                </li>
              </ul>

              </div>
              <div className="flex flex-col w-1/2 p-8 gap-6">
                <div className="flex flex-row items-start">
                <img 
                  src="/pages-bg/13.png" 
                  alt="Background image" 
                  className="rounded-lg w-full h-full object-cover shadow-2xl filter brightness-90 contrast-125 saturate-150" 
                />
                </div>
                <div className="p-8 border-2">
                  <h4>AFFF Film Industry Professionals are key stakeholders committed to driving the growth of the African film sector, advancing its global presence, and leveraging strategic partnerships for sustainable success.</h4>
                </div>
                
              </div>



            </div>
           
            


          </section>

          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">Event Highlights</h2>
            </div>
            
            <SessionsCard />
          </section>

          <section className="w-full bg-[#FAF0E6] p-6 sm:p-8 rounded-lg mb-12 sm:mb-16 text-lexend">
          <h2 className="font-header text-4xl sm:text-3xl lg:text-6xl font-bold mb-6 tracking-wider text-custom-base">WHY YOU SHOULD EXHIBIT AT AFFF</h2>
            <p className="mb-4 text-sm sm:text-base">
              Exhibiting at AFFF positions you at the center of the film industry's most influential event. This is your
              chance to connect with a global audience of decision-makers and investors who are actively seeking new
              opportunities. By showcasing your work, you can attract crucial funding, establish valuable partnerships,
              and elevate your profile among key industry players. This is a high-impact platform that offers
              unparalleled access to the people who can drive your success in the film industry.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              To discuss your exhibit options contact us at <b>project@africafilmfinanceforum.com</b>
            </p>
            <Link 
                to="/exhibit/how-to-exhibit" 
                className="px-10 py-2 mt-4 mb-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
              Secure Your Space
          </Link>
          </section>

          <div className="text-center mt-8">
            <p className="font-bold font-header tracking-wider text-[3rem] mb-4">EARLY REGISTRATION IS IMPORTANT TO SECURE A SPACE</p>
            <Link to="/exhibit/how-to-exhibit" className="w-full sm:w-auto bg-custom-red font-roboto font-bold text-white px-4 sm:px-6 py-2 sm:py-3 text-center text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 inline-block">HOW TO EXHIBIT</Link>
          </div>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccreditedFilmProductionCompaniesPage;