import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import cineTourBgImage from "../assets/film-premier-bg.jpg";

const CineTourExpoPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="CINETOUR EXPO" 
        description="Integrating tourism with filmmaking to showcase Africa's stunning attractions."
        backgroundImage={cineTourBgImage}
      />
      <main className="bg-white mb-12 font-lexend sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 tracking-wider text-custom-base">FILM COMMISSION AND PRODUCTION SERVICES</h2>
            <p className="mb-4 text-sm sm:text-base">
              AFFF uniquely integrates tourism with filmmaking by strategically featuring Africa's stunning attractions
              within movie scenes. This innovative approach not only enhances the cinematic experience but also
              highlights Africa's incredible destinations to a global audience. By showcasing these attractions in films,
              AFFF aims to attract foreign investment and boost tourism to the continent, creating a dynamic synergy
              between the film industry and travel. This exciting blend of film and tourism promises to captivate
              viewers and drive new opportunities for exploring Africa's rich cultural and natural heritage.
            </p>
          </section>

          <section className="mb-8 sm:mb-12 font-lexend">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 tracking-wider text-custom-base">WHY YOU SHOULD EXHIBIT AT AFFF</h2>
            <p className="mb-4 text-sm sm:text-base">
              Exhibiting at AFFF offers a distinctive chance to highlight your business at the intersection of film and
              tourism. As the event integrates Africa's spectacular attractions into major film projects, you'll be
              exposed to over 3,000 global attendees, including influential financiers, industry leaders, and tourism
              experts. This unique setting allows you to showcase how your products or services align with this
              innovative approach, creating new opportunities for investment and partnerships. By participating,
              you'll position your brand as a key player in this exciting fusion, driving interest and opening doors to
              significant growth and visibility in both the film and tourism sectors.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              To discuss your exhibit options contact us at project@africafilmfinanceforum.com
            </p>
            <button 
              onClick={() => setShowContactForm(true)}
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
            >
              CONTACT US
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default CineTourExpoPage;