// PitchSubmissionForm.jsx
import React, { useState } from 'react';
import BaseForm from './BaseForm';

const API_URL = 'https://updates.mtnasap.cloud/afff/api';

const PitchSubmissionForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    projectTitle: '',
    genre: '',
    logline: '',
    synopsis: '',
    director: '',
    producer: '',
    budget: '',
    contactName: '',
    email: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    
    if (!formData.projectTitle.trim()) {
      setError("Project title is required");
      return false;
    }
    if (!formData.director.trim()) {
      setError("Director name is required");
      return false;
    }
    if (!formData.producer.trim()) {
      setError("Producer name is required");
      return false;
    }
    if (!formData.synopsis.trim()) {
      setError("Synopsis is required");
      return false;
    }
    if (formData.synopsis.length < 100) {
      setError("Synopsis should be at least 100 characters long");
      return false;
    }
    if (!formData.logline.trim()) {
      setError("Logline is required");
      return false;
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
      setError("Please enter a valid phone number");
      return false;
    }
    if (!formData.budget.trim()) {
      setError("Please provide an estimated budget");
      return false;
    }
    if (isNaN(formData.budget) || Number(formData.budget) <= 0) {
      setError("Please enter a valid budget amount");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
    setSuccess('');
  };

  const formatBudget = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');
    // Format with commas
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleBudgetChange = (e) => {
    const formatted = formatBudget(e.target.value);
    setFormData(prev => ({
      ...prev,
      budget: formatted
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/pitch-submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          budget: Number(formData.budget.replace(/,/g, '')),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Submission failed');
      }

      const data = await response.json();
      
      setSuccess('Thank you for submitting your pitch. We will review your submission and get back to you soon.');
      setFormData({
        projectTitle: '',
        genre: '',
        logline: '',
        synopsis: '',
        director: '',
        producer: '',
        budget: '',
        contactName: '',
        email: '',
        phone: '',
      });
      
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.error('Pitch submission error:', error);
      setError(error.message || 'Submission failed. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="Pitch Submission" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Project Details Section */}
        <div className="bg-gray-50 p-4 rounded-lg mb-6">
          <h3 className="text-lg font-semibold mb-4">Project Details</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="projectTitle" className="block text-sm font-medium text-gray-700 mb-1">
                Project Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="projectTitle"
                name="projectTitle"
                value={formData.projectTitle}
                onChange={handleChange}
                className="form-input w-full focus:ring-2 focus:ring-custom-red"
                required
              />
            </div>

            <div>
              <label htmlFor="genre" className="block text-sm font-medium text-gray-700 mb-1">
                Genre <span className="text-red-500">*</span>
              </label>
              <select
                id="genre"
                name="genre"
                value={formData.genre}
                onChange={handleChange}
                className="form-select w-full focus:ring-2 focus:ring-custom-red"
                required
              >
                <option value="">Select Genre</option>
                <option value="Drama">Drama</option>
                <option value="Comedy">Comedy</option>
                <option value="Action">Action</option>
                <option value="Documentary">Documentary</option>
                <option value="Horror">Horror</option>
                <option value="Thriller">Thriller</option>
                <option value="Romance">Romance</option>
                <option value="SciFi">Science Fiction</option>
                <option value="Animation">Animation</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div>
              <label htmlFor="logline" className="block text-sm font-medium text-gray-700 mb-1">
                Logline <span className="text-red-500">*</span>
                <span className="text-gray-500 text-xs ml-2">(One-sentence summary)</span>
              </label>
              <textarea
                id="logline"
                name="logline"
                value={formData.logline}
                onChange={handleChange}
                rows="2"
                className="form-textarea w-full focus:ring-2 focus:ring-custom-red"
                required
              />
            </div>

            <div>
              <label htmlFor="synopsis" className="block text-sm font-medium text-gray-700 mb-1">
                Synopsis <span className="text-red-500">*</span>
                <span className="text-gray-500 text-xs ml-2">(Minimum 100 characters)</span>
              </label>
              <textarea
                id="synopsis"
                name="synopsis"
                value={formData.synopsis}
                onChange={handleChange}
                rows="4"
                className="form-textarea w-full focus:ring-2 focus:ring-custom-red"
                required
                minLength={100}
              />
            </div>
          </div>
        </div>

        {/* Team Details Section */}
        <div className="bg-gray-50 p-4 rounded-lg mb-6">
          <h3 className="text-lg font-semibold mb-4">Team Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="director" className="block text-sm font-medium text-gray-700 mb-1">
                Director <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="director"
                name="director"
                value={formData.director}
                onChange={handleChange}
                className="form-input w-full focus:ring-2 focus:ring-custom-red"
                required
              />
            </div>

            <div>
              <label htmlFor="producer" className="block text-sm font-medium text-gray-700 mb-1">
                Producer <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="producer"
                name="producer"
                value={formData.producer}
                onChange={handleChange}
                className="form-input w-full focus:ring-2 focus:ring-custom-red"
                required
              />
            </div>
          </div>
        </div>

        {/* Contact Details Section */}
        <div className="bg-gray-50 p-4 rounded-lg mb-6">
          <h3 className="text-lg font-semibold mb-4">Contact Details</h3>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="contactName" className="block text-sm font-medium text-gray-700 mb-1">
                  Contact Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="contactName"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleChange}
                  className="form-input w-full focus:ring-2 focus:ring-custom-red"
                  required
                />
              </div>

              <div>
                <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mb-1">
                  Estimated Budget (USD) <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="budget"
                  name="budget"
                  value={formData.budget}
                  onChange={handleBudgetChange}
                  className="form-input w-full focus:ring-2 focus:ring-custom-red"
                  required
                  placeholder="e.g., 100,000"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-input w-full focus:ring-2 focus:ring-custom-red"
                  required
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                  Phone <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-input w-full focus:ring-2 focus:ring-custom-red"
                  required
                  placeholder="+234 800 000 0000"
                />
              </div>
            </div>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {success && (
          <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">{success}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-custom-red"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`
              px-6 py-2 rounded-md text-white
              ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-custom-red hover:bg-red-600'}
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red
              transition-colors duration-200
            `}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Submitting...
              </span>
            ) : (
              'Submit Pitch'
            )}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default PitchSubmissionForm;