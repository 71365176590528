import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription
} from '../component/ui/card';
import { TabsList, TabsTrigger, TabsContent, Tabs } from '../component/ui/tabs';
import { Alert, AlertTitle, AlertDescription } from '../component/ui/alert';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell
} from '../component/ui/table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../component/ui/dialog";
import { ScrollArea } from "../component/ui/scroll-area";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import {
  Users,
  Mail,
  FileText,
  Briefcase,
  TrendingUp,
  Building,
  Activity,
  MessageSquare,
  Film,
  GraduationCap,
  Download,
  RefreshCw,
  Calendar,
  Filter
} from 'lucide-react';
import { format } from 'date-fns';

// API Configuration
const api = axios.create({
  baseURL: 'https://updates.mtnasap.cloud/afff/api/admin',
  withCredentials: true,
  timeout: 30000, // 30 second timeout
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add request interceptor
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add response interceptor
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('auth_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// API Service
const apiService = {
  fetchStats: () => api.get('/stats'),
  fetchConnexRegistrations: () => api.get('/connex-registrations'),
  fetchSummitRegistrations: () => api.get('/summit-registrations'),
  fetchPitchSubmissions: () => api.get('/pitch-submissions'),
  fetchFinanciers: () => api.get('/financier-accreditations'),
  fetchFilmScreenings: () => api.get('/film-screenings'),
  fetchMasterclass: () => api.get('/masterclass'),
  fetchSubscribers: () => api.get('/subscribers'),
  fetchActivities: () => api.get('/recent-activities'),
  fetchTrends: () => api.get('/trends'),
  fetchCountryStats: () => api.get('/country-stats'),
  updateStatus: (entityType, id, status) => 
    api.put(`/${entityType}/${id}/status`, { status })
};

// Constants
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // Base delay in milliseconds

// Custom Components
const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="font-lexend text-xs"
    >
      {`${name} (${(percent * 100).toFixed(0)}%)`}
    </text>
  );
};

// Loading Component
const LoadingSpinner = ({ error, onRetry }) => (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mb-4"></div>
    {error && (
      <>
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={onRetry}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Retry Loading
        </button>
      </>
    )}
  </div>
);

// Stat Card Component
const StatCard = ({ title, value, icon, data, columns }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Card className="cursor-pointer hover:shadow-lg transition-shadow">
          <CardContent className="p-6">
            <div className="flex items-center space-x-4">
              <div className="bg-slate-100 p-3 rounded-full">
                {React.cloneElement(icon, { className: "h-6 w-6 text-custom-base" })}
              </div>
              <div>
                <p className="font-roboto text-sm text-gray-600">{title}</p>
                <h3 className="font-lexend text-2xl font-bold">{value?.toLocaleString() || 0}</h3>
              </div>
            </div>
          </CardContent>
        </Card>
      </DialogTrigger>
      
      <DialogContent className="max-w-4xl max-h-[80vh]">
        <DialogHeader>
          <DialogTitle className="font-roboto text-xl">{title} Details</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[60vh]">
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHead key={column.accessor} className="font-roboto">
                    {column.header}
                  </TableHead>
                ))}
                <TableHead>Date</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.accessor} className="font-lexend">
                      {item[column.accessor]}
                    </TableCell>
                  ))}
                  <TableCell className="font-lexend">
                    {format(new Date(item.created_at), 'MMM d, yyyy')}
                  </TableCell>
                  <TableCell className="font-lexend">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      item.status === 'approved' ? 'bg-green-100 text-green-800' :
                      item.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {item.status || 'pending'}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

// Overview Tab Component
const OverviewTab = ({ data }) => {
  const pieChartData = [
    { name: 'Pitch Submissions', value: data?.stats?.totalPitchSubmissions || 0 },
    { name: 'Film Screenings', value: data?.stats?.breakdownStats?.filmScreenings || 0 },
    { name: 'Masterclass', value: data?.stats?.breakdownStats?.masterclass || 0 },
    { name: 'Financiers', value: data?.stats?.totalFinanciers || 0 }
  ];

  const trendsData = data?.trends?.map(t => ({
    month: t.month,
    'Connex': t.connex_count,
    'Summit': t.summit_count
  })) || [];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Card>
        <CardHeader>
          <CardTitle className="font-roboto">Registration Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={trendsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="month" 
                  tickFormatter={(value) => format(new Date(value), 'MMM')}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Connex"
                  stroke={COLORS[0]}
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
                <Line
                  type="monotone"
                  dataKey="Summit"
                  stroke={COLORS[1]}
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="font-roboto">Submission Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={<CustomLabel />}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

// Data Table Component
const DataTable = ({ data, columns, onUpdateStatus }) => (
  <Table>
    <TableHeader>
      <TableRow>
        {columns.map((column) => (
          <TableHead key={column.accessor} className="font-roboto">
            {column.header}
          </TableHead>
        ))}
        <TableHead>Status</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {data?.map((item, index) => (
        <TableRow key={index}>
          {columns.map((column) => (
            <TableCell key={column.accessor} className="font-lexend">
              {item[column.accessor]}
            </TableCell>
          ))}
          <TableCell>
            <select
              value={item.status || 'pending'}
              onChange={(e) => onUpdateStatus(item.id, e.target.value)}
              className="border rounded p-1 font-lexend"
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </TableCell>
          <TableCell>
            <button 
              onClick={() => onUpdateStatus(item.id, item.status)}
              className="text-blue-600 hover:text-blue-800 font-lexend transition-colors"
            >
              Update
            </button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

// Tab Components
const ConnexTab = ({ data, onUpdateStatus }) => (
  <Card>
    <CardHeader>
      <CardTitle className="font-roboto">Connex Registrations</CardTitle>
      <CardDescription className="font-lexend">
        Manage all Connex event registrations
      </CardDescription>
    </CardHeader>
    <CardContent>
      <DataTable
        data={data}
        columns={[
          { header: 'Name', accessor: 'name' },
          { header: 'Email', accessor: 'email' },
          { header: 'Company', accessor: 'company' },
          { header: 'Type', accessor: 'type' }
        ]}
        onUpdateStatus={(id, status) => onUpdateStatus('connex', id, status)}
      />
    </CardContent>
  </Card>
);

const SummitTab = ({ data, onUpdateStatus }) => (
  <Card>
    <CardHeader>
      <CardTitle className="font-roboto">Summit Registrations</CardTitle>
      <CardDescription className="font-lexend">
        Manage all Summit event registrations
      </CardDescription>
    </CardHeader>
    <CardContent>
      <DataTable
        data={data}
        columns={[
          { header: 'Name', accessor: 'full_name' },
          { header: 'Email', accessor: 'email' },
          { header: 'Badge Type', accessor: 'badge_type' },
          { header: 'Company', accessor: 'company' }
        ]}
        onUpdateStatus={(id, status) => onUpdateStatus('summit', id, status)}
      />
    </CardContent>
  </Card>
);

const PitchesTab = ({ data, onUpdateStatus }) => (
  <Card>
    <CardHeader>
      <CardTitle className="font-roboto">Pitch Submissions</CardTitle>
      <CardDescription className="font-lexend">
        Review and manage pitch submissions
      </CardDescription>
    </CardHeader>
    <CardContent>
      <DataTable
        data={data}
        columns={[
          { header: 'Project', accessor: 'project_title' },
          { header: 'Contact', accessor: 'contact_name' },
          { header: 'Genre', accessor: 'genre' },
          { header: 'Budget', accessor: 'budget' }
        ]}
        onUpdateStatus={(id, status) => onUpdateStatus('pitches', id, status)}
      />
    </CardContent>
  </Card>
);

const FinanciersTab = ({ data, onUpdateStatus }) => (
  <Card>
    <CardHeader>
      <CardTitle className="font-roboto">Financier Accreditations</CardTitle>
      <CardDescription className="font-lexend">
        Manage financier accreditations
      </CardDescription>
    </CardHeader>
    <CardContent>
      <DataTable
        data={data}
        columns={[
          { header: 'Name', accessor: 'financier_name' },
          { header: 'Company', accessor: 'company_name' },
          { header: 'Email', accessor: 'email' },
          { header: 'Country', accessor: 'country' }
        ]}
        onUpdateStatus={(id, status) => onUpdateStatus('financiers', id, status)}
      />
    </CardContent>
  </Card>
);

const ActivitiesTab = ({ activities }) => (
  <Card>
    <CardHeader>
      <CardTitle className="font-roboto">Recent Activities</CardTitle>
      <CardDescription className="font-lexend">
        Latest actions and updates across the platform
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        {activities?.map((activity, index) => (
          <div 
            key={index}
            className="flex items-center p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
          >
            <div className="mr-4">
              <Activity className="h-5 w-5 text-gray-500" />
            </div>
            <div>
              <p className="font-roboto font-medium">{activity.type}</p>
              <p className="font-lexend text-sm text-gray-600">
                {activity.contact_name} - {activity.title}
              </p>
              <p className="font-lexend text-xs text-gray-400">
                {format(new Date(activity.created_at), 'PPp')}
              </p>
            </div>
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

// Main Dashboard Component
const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    stats: {
      totalConnexRegistrations: 0,
      totalSummitRegistrations: 0,
      totalPitchSubmissions: 0,
      totalFinanciers: 0,
      totalSubmissions: 0,
      totalSubscribers: 0,
      breakdownStats: {
        filmScreenings: 0,
        masterclass: 0,
        financiers: 0,
        pitchSubmissions: 0
      }
    },
    connexRegistrations: [],
    summitRegistrations: [],
    pitchSubmissions: [],
    financiers: [],
    filmScreenings: [],
    masterclass: [],
    subscribers: [],
    activities: [],
    trends: [],
    countryStats: []
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  const [retryCount, setRetryCount] = useState(0);

  // Function to handle exponential backoff
  const getRetryDelay = (retryCount) => {
    return Math.min(1000 * Math.pow(2, retryCount), 10000);
  };

  const fetchDashboardData = async (retry = false) => {
    if (retry && retryCount >= MAX_RETRIES) {
      setError('Maximum retry attempts reached. Please refresh the page to try again.');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError('');
    
    try {
      const [
        stats,
        connexRegs,
        summitRegs,
        pitchSubs,
        financiers,
        filmScreenings,
        masterclass,
        subscribers,
        activities,
        trends,
        countryStats
      ] = await Promise.all([
        apiService.fetchStats(),
        apiService.fetchConnexRegistrations(),
        apiService.fetchSummitRegistrations(),
        apiService.fetchPitchSubmissions(),
        apiService.fetchFinanciers(),
        apiService.fetchFilmScreenings(),
        apiService.fetchMasterclass(),
        apiService.fetchSubscribers(),
        apiService.fetchActivities(),
        apiService.fetchTrends(),
        apiService.fetchCountryStats()
      ]);

      setDashboardData({
        stats: stats.data.data,
        connexRegistrations: connexRegs.data.data,
        summitRegistrations: summitRegs.data.data,
        pitchSubmissions: pitchSubs.data.data,
        financiers: financiers.data.data,
        filmScreenings: filmScreenings.data.data,
        masterclass: masterclass.data.data,
        subscribers: subscribers.data.data,
        activities: activities.data.data,
        trends: trends.data.data,
        countryStats: countryStats.data.data
      });

      setSuccess('Dashboard data loaded successfully');
      setRetryCount(0); // Reset retry count on success
    } catch (err) {
      console.error('Dashboard data fetch error:', err);
      
      const errorMessage = err.response?.data?.message || 'Failed to fetch dashboard data';
      setError(errorMessage);

      // Implement retry with exponential backoff for specific errors
      if (err.response?.status === 429 || // Rate limit exceeded
          err.code === 'ECONNABORTED' || // Timeout
          err.code === 'ERR_NETWORK') { // Network error
        setRetryCount(prev => prev + 1);
        const delay = getRetryDelay(retryCount);
        setTimeout(() => {
          fetchDashboardData(true);
        }, delay);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchDashboardData();

    return () => {
      controller.abort(); // Cleanup pending requests
    };
  }, []);

  const updateEntityStatus = async (entityType, id, status) => {
    try {
      setIsLoading(true);
      await apiService.updateStatus(entityType, id, status);
      await fetchDashboardData();
      setSuccess(`${entityType.charAt(0).toUpperCase() + entityType.slice(1)} status updated successfully`);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update status';
      setError(errorMessage);
      console.error('Status update error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDataExport = () => {
    try {
      const dataStr = JSON.stringify(dashboardData, null, 2);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = `afff-dashboard-export-${format(new Date(), 'yyyy-MM-dd')}.json`;
      link.href = url;
      link.click();
      URL.revokeObjectURL(url); // Cleanup
      setSuccess('Data exported successfully');
    } catch (err) {
      setError('Failed to export data');
      console.error('Export error:', err);
    }
  };

  if (isLoading) {
    return <LoadingSpinner error={error} onRetry={() => fetchDashboardData(true)} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-slate-100 p-4 shadow-md">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <img 
              src="/summiit-logo.png" 
              alt="AFFF Logo" 
              className="h-12"
            />
          </div>
          <h1 className="font-roboto text-2xl font-bold text-custom-base">
            Admin Dashboard | Africa Film Finance Forum (CONNEX & SUMMIT)
          </h1>
        </div>
      </div>

      <div className="container mx-auto p-6">
        {/* Alert Messages */}
        {success && (
          <Alert className="mb-4">
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>{success}</AlertDescription>
          </Alert>
        )}

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-8">
          <StatCard
            title="Connex Registrations"
            value={dashboardData.stats.totalConnexRegistrations}
            icon={<Users />}
            data={dashboardData.connexRegistrations}
            columns={[
              { header: 'Name', accessor: 'name' },
              { header: 'Email', accessor: 'email' },
              { header: 'Company', accessor: 'company' },
              { header: 'Type', accessor: 'type' }
            ]}
          />
          
          <StatCard
            title="Summit Registrations"
            value={dashboardData.stats.totalSummitRegistrations}
            icon={<Building />}
            data={dashboardData.summitRegistrations}
            columns={[
              { header: 'Name', accessor: 'full_name' },
              { header: 'Email', accessor: 'email' },
              { header: 'Badge Type', accessor: 'badge_type' },
              { header: 'Company', accessor: 'company' }
            ]}
          />
          
          <StatCard
            title="Pitch Submissions"
            value={dashboardData.stats.totalPitchSubmissions}
            icon={<FileText />}
            data={dashboardData.pitchSubmissions}
            columns={[
              { header: 'Project', accessor: 'project_title' },
              { header: 'Contact', accessor: 'contact_name' },
              { header: 'Genre', accessor: 'genre' },
              { header: 'Budget', accessor: 'budget' }
            ]}
          />

          <StatCard
            title="Financier Accreditations"
            value={dashboardData.stats.totalFinanciers}
            icon={<Briefcase />}
            data={dashboardData.financiers}
            columns={[
              { header: 'Name', accessor: 'financier_name' },
              { header: 'Company', accessor: 'company_name' },
              { header: 'Email', accessor: 'email' },
              { header: 'Country', accessor: 'country' }
            ]}
          />
        </div>

        {/* Tabs */}
        <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
          <TabsList className="grid w-full grid-cols-4 lg:grid-cols-6">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="connex">Connex</TabsTrigger>
            <TabsTrigger value="summit">Summit</TabsTrigger>
            <TabsTrigger value="pitches">Pitches</TabsTrigger>
            <TabsTrigger value="financiers">Financiers</TabsTrigger>
            <TabsTrigger value="activities">Activities</TabsTrigger>
          </TabsList>

          <TabsContent value="overview">
            <OverviewTab data={dashboardData} />
          </TabsContent>

          <TabsContent value="connex">
            <ConnexTab 
              data={dashboardData.connexRegistrations} 
              onUpdateStatus={updateEntityStatus} 
            />
          </TabsContent>

          <TabsContent value="summit">
            <SummitTab 
              data={dashboardData.summitRegistrations}
              onUpdateStatus={updateEntityStatus}
            />
          </TabsContent>

          <TabsContent value="pitches">
            <PitchesTab 
              data={dashboardData.pitchSubmissions}
              onUpdateStatus={updateEntityStatus}
            />
          </TabsContent>

          <TabsContent value="financiers">
            <FinanciersTab 
              data={dashboardData.financiers}
              onUpdateStatus={updateEntityStatus}
            />
          </TabsContent>

          <TabsContent value="activities">
            <ActivitiesTab activities={dashboardData.activities} />
          </TabsContent>
        </Tabs>

        {/* Action Buttons */}
        <div className="fixed bottom-8 right-8 flex gap-4">
          <button
            onClick={() => fetchDashboardData()}
            className="bg-white hover:bg-gray-100 text-gray-800 px-6 py-3 rounded-full shadow-lg flex items-center space-x-2 transition-all duration-300 border border-gray-200"
          >
            <RefreshCw className="h-5 w-5" />
            <span>Refresh</span>
          </button>

          <button
            onClick={handleDataExport}
            className="bg-custom-red hover:bg-red-700 text-white px-6 py-3 rounded-full shadow-lg flex items-center space-x-2 transition-all duration-300"
          >
            <Download className="h-5 w-5" />
            <span>Export Data</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;