import React from "react";
import male from "../assets/male-speaker.png";
import female from "../assets/female-speaker-min.png";
import chief from "../assets/chief-min.png";

const Speakers = () => {
  return (
    <section className="lg:pt-[5rem] pt-[2rem] lg:pb-[10rem] pb-[2rem] px-[4%]">
      <div>
      <h4 className="font-header capitalize tracking-wider font-semibold text-center lg:pt-[4.43rem] pt-[2rem] lg:text-[3.5rem] text-[2.5rem]">
        KEYNOTE SPEAKERS AND CO-CHAIRS
      </h4>
      <div className="flex lg:flex-row flex-col lg:mt-12 mt-8 lg:items-start items-center justify-center gap-[3.5rem]">
        {/* Female speaker */}
        <div className="flex flex-col justify-center items-center">
          <div className="w-[20rem] h-[24rem] lg:w-[24rem] lg:h-[28rem] overflow-hidden">
            <img
              className="w-full h-full object-cover object-center"
              src={female}
              alt="female-speaker"
            />
          </div>
          <div
            className="bg-[#574C75] lg:mt-8 mt-4 w-full max-w-[27rem] h-[6rem] flex items-center justify-center"
            style={{
              clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
              boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              className="bg-white w-[calc(100%-0.1rem)] pt-[0.5rem] pb-[0.31rem] px-[2.5rem] h-[5.9rem]"
              style={{
                clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
              }}
            >
              <div className="lg:text-left text-center">
                <p className="font-roboto lg:text-[1.5rem] text-[1.3rem]">
                  Hon.Min Hanatu Musa Musawa
                </p>
                <p className="font-lato font-normal -mb-[0.2rem]">
                  Minister Arts, Culture & Creative Economy
                </p>
                <p className="font-lato font-normal">
                  Federal Republic of Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Male Speaker */}
        <div className="flex flex-col justify-center items-center">
          <div className="w-[20rem] h-[24rem] lg:w-[24rem] lg:h-[28rem] overflow-hidden">
            <img
              className="w-full h-full object-cover object-center"
              src={chief}
              alt="male-speaker"
            />
          </div>
          <div
            className="bg-[#574C75] lg:mt-8 mt-4 w-full max-w-[27rem] h-[6rem] flex items-center justify-center"
            style={{
              clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
              boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              className="bg-white w-[calc(100%-0.1rem)] pt-[0.5rem] pb-[0.31rem] px-[2.5rem] h-[5.9rem]"
              style={{
                clipPath: "polygon(4% 1%, 91% 0, 100% 100%, 0% 100%)",
              }}
            >
              <div className="lg:text-left text-center">
                <p className="font-roboto lg:text-[1.5rem] text-[1.3rem]">
                  Hon.Min John Enoh
                </p>
                <p className="font-lato font-normal -mb-[0.2rem]">
                  Minister of State Trade and Investment (Industry)
                </p>
                <p className="font-lato font-normal">
                  Federal Republic of Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Speakers;