// PaymentVerification.jsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const API_URL = 'https://updates.mtnasap.cloud/afff/api';

const PaymentVerification = () => {
  const [status, setStatus] = useState('Verifying payment...');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get('reference');

      if (!reference) {
        setError('Invalid payment reference.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_URL}/payments/verify/${reference}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Verification failed');
        }

        const data = await response.json();
        
        if (data.status === 'success') {
          setStatus('Payment successful! Redirecting...');
          setTimeout(() => {
            navigate('/payment-success');
          }, 2000);
        } else {
          throw new Error(data.message || 'Payment verification failed');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setError(error.message || 'Payment verification failed. Please contact support.');
      } finally {
        setIsLoading(false);
      }
    };

    verifyPayment();
  }, [location, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-8">
        <h2 className="text-2xl font-bold text-center mb-6">Payment Verification</h2>
        
        {isLoading ? (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-red mx-auto mb-4"></div>
            <p className="text-lg text-gray-600">{status}</p>
          </div>
        ) : error ? (
          <div className="text-center">
            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded mb-6">
              <p className="text-red-700">{error}</p>
            </div>
            <button
              onClick={() => navigate('/pricing')}
              className="bg-custom-red hover:bg-red-600 text-white font-bold py-2 px-6 rounded-md transition duration-200"
            >
              Return to Pricing
            </button>
          </div>
        ) : (
          <div className="text-center">
            <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded mb-6">
              <p className="text-green-700">{status}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentVerification;